<template>
  <div>
    <div class="flex md12">
      <va-card :title="'find users'">
        <div class="row align--center">
          <div class="flex xs12 md3">
            <va-input
              v-model="term"
              :placeholder="'Search by name or email'"
              removable
            >
              <va-icon
                name="fa fa-search"
                slot="prepend"
              />
            </va-input>
          </div>
          <div class="flex xs12 md3">
          <va-select
            label="Filters"
            v-model="filterModel"
            text-by="label"
            key-by="value"
            @input="filterBy()"
            :options="filterOptions"
          />
        </div>
          <div class="flex xs12 md3 offset--md3">
            <va-select
              v-model="perPage"
              :label="$t('tables.perPage')"
              :options="perPageOptions"
              no-clear
            />
          </div>
        </div>
          <va-data-table
            v-if="notSearching"
            :fields="fields"
            :data="users"
            api-mode
            :loading="loading"
            :per-page="parseInt(perPage)"
            :totalPages="totalPages"
            @page-selected="handlePageChange"
            @row-clicked="showUser"
            hoverable
            clickable
          >
          <!-- Avatar IMG -->
          <template
          slot="avatar"
          slot-scope="props"
          >
            <va-avatar>
              <img
                :src="props.rowData.photoURL"
                class="avatar data-table-server-pagination---avatar"
              >
            </va-avatar>
          </template>
          <!-- /end -  Avatar IMG -->
          <!-- /start - lastLogin -->
          <template
            slot="lastLogin"
            slot-scope="props"
          >
            {{ props.rowData.lastLogin ? $moment(props.rowData.lastLogin.seconds * 1000).format('MM/DD/YYYY') : 'N/A' }}
          </template>
          <!-- /end - lastLogin -->
        </va-data-table>
        <va-card v-else>
          <div class="text-center">
           <i class="fa fa-spinner fa-spin fa-3x fa-fw"></i>
          </div>
        </va-card>
      </va-card>
    </div>
  </div>
</template>

<script>
import firebase from 'firebase';
import UserTable from '../../../class/UserTable';
import axios from '@/scripts/interceptor.js'
export default {
  name: 'ServerSide',
  /**
   * @description Setup the data for the component
  */
  data() {
    return {
      loading: true,
      users: [],
      term: "",
      notSearching: true,
      pageSnapshots: [],
      cachedPages: {},
      //current page is the current page number
      currentPage: 1,
      //total pages is the pagination number [1,2,3,4,5...]
      totalPages: 5,
      //per page is the amount of items per page
      perPage: '10',
      perPageOptions: ['1','5','10', '50', '100', '250'],
      filterModel: '',
      filterChoices: {
        lastUserID: null,
        freeSub: null,
        state: null,
        logged: null,
        completed: null
      },
      filterOptions: [
        {label: 'Free Subscriber', value: 'freeSub'},
        {label: 'Logged In', value: 'logged'},
        {label: 'State', value: 'state'},
        {label: 'Completed Exam', value: 'completed'},
     ],
    };
  },
  async created() {
    await this.getPagination();
    await this.fetchUsers(this.currentPage, this.perPage);
  },
  /**
   * @description Watch for changes in the pagination and update the table
 */
  watch: {
    currentPage: {
      handler: function () {
        if(!this.notSearching) return;

        if(!this.filterModel.value){
         this.fetchUsers(this.currentPage, this.perPage);
        }else{
          this.filterBy(true)
        }

      },
    },
    term: {
      handler: function () {
        this.debouncedSearch();
      },
    },
    perPage: {
      handler: async function () {
        this.resetCache();
        await this.getPagination();
        if (this.term) {
          this.searchUsers(this.term, 0, this.perPage);
        } else {
          await this.fetchUsers(this.currentPage, this.perPage);
        }
      },
    },
  },
  /**
   * @description Handle/Fetch the page change event
  */
  methods: {
    showUser(user) {
      this.$router.push({path: 'edituser/' + user.objectID});
    },
    /**
     * @description this method will make the first research in the users with a specific filter. 
    */
    async filterBy(isPaginationChange = false) {
      if (!this.filterModel.value) {
        this.resetCache();
        await this.getPagination();
        await this.fetchUsers(this.currentPage, this.perPage);
        return;
      }

      try {
        this.loading = true;
        const TableFilterInstance = new UserTable(this.currentPage, this.perPage, this.totalPages, this.users, firebase);
        const customQuery = await TableFilterInstance.handleFilterSelection(this.filterModel.value, this.filterChoices, isPaginationChange);
        await this.store(customQuery.data.items);
        this.totalPages = customQuery.data.totalPageCount;
        this.filterChoices = customQuery.options;
      } catch (error) {
        console.log(error);
        this.$swal.fire({
          icon: 'warning',
          title: 'Oops...',
          text: error.message,
        });
        this.filterModel = undefined;
      } finally {
        this.loading = false;
      }
    },
    /**
     * @description Performs a debounced search or resets the user list when the search term changes.
     * When a search term is present, it searches for users with the given term.
     * Otherwise, it resets the cache, gets pagination, and fetches the first page of users.
     * The debounce delays the search by 500ms to minimize the number of requests triggered by rapid input changes.
    */
    debouncedSearch: _.debounce(async function () {
      if (this.term) {
        this.notSearching = false;
        await this.searchUsers(this.term, 0, this.perPage);
        this.notSearching = true;
      } else {
        this.notSearching = false;
        this.resetCache();
        await this.getPagination();
        await this.fetchUsers(1, this.perPage);
        this.notSearching = true;
      }
    }, 500),
    /**
     * @description Resets the cache for storing fetched user pages and their boundaries.
     * Clears the 'cachedPages' object and 'pageSnapshots' array to remove previously fetched data and make room for new data.
    */
    resetCache() {
      this.cachedPages = {};
      this.pageSnapshots = [];
    },
    /**
     * @description Handles the page change event for the user list.
     * If there's a search term, perform a search for the new page.
     * Otherwise, update the current page number.
     * @param {number} page - The target page number to change to.
    */
    handlePageChange(page) {
      if (this.term) {
        this.searchUsers(this.term, (page - 1), this.perPage);
      }else{
        this.currentPage = page;
      }
    },
    /**
     * @description Searches users based on the given search term and fetches the specified page of results.
     * Calls the 'searchUsers' Firebase/Express function with the search term, items per page, and page number.
     * Updates the component's data with the received search results and total pages.
     *
     * @param {string} searchTerm - The search term used to filter users.
     * @param {number} page - The target page number to fetch.
     * @param {number} itemsPerPage - The number of items to display per page.
    */
    async searchUsers(searchTerm, page, itemsPerPage) {
      this.loading = true;
      try {
        const result = await axios.post(window.firebaseURL + `api/admin/searchUsers`, {
          search: searchTerm,
          hitsPerPage: parseInt(itemsPerPage),
          page: page,
        })
        this.users = result.data?.data.hits;
        this.totalPages = result.data?.data?.totalPages;
        this.loading = false;
      } catch (error) {
        this.loading = false;
      }
    },
    /**
   * @description Calculate the total number of pages for pagination based on the total number of items and items per page.
   * Fetches the total number of users from the Firestore 'users' collection, then calculates the number of pages needed for pagination.
   * The result is stored in the component's data.
   */
    async getPagination() {
        let usersTable = firebase.firestore().collection('users');
        let querySnapshot = await usersTable.get();
        const totalItems = querySnapshot.size;
        const itemsPerPage = parseInt(this.perPage);
        this.totalPages = Math.floor(totalItems / itemsPerPage);
     },
     async store(querySnapshot, alreadyFetchedPages = false) {
      const fetchedUsers = [];
      querySnapshot.forEach((doc) => {
        let data = doc.data();
        fetchedUsers.push({
          objectID: doc.id,
          photoURL: data.photoURL,
          displayName: data.displayName,
          provider: data.provider,
          email: data.email,
          lastLogin: data.lastLogin,
        });
      });
      
      this.users = fetchedUsers;
     },
     /**
     * Fetches a page of users and stores the results in the component's data.
     * Utilizes caching to minimize the number of queries to the Firestore.
     *
     * @param {number} page - The target page number to fetch.
     * @param {number} itemsPerPage - The number of items to display per page.
     * @param {string} search - If the search is active.
    */
    async fetchUsers(page, itemsPerPage, query = false, search = '') {
      this.loading = true;
      this.users = [];
      
      // Check if the current page has been fetched before
      const targetPage = page;
      if (this.cachedPages[targetPage] && !search && !query) {
        this.users = this.cachedPages[targetPage];
        this.loading = false;
        return;
      }

      let usersQuery = query;
      if (!query) {
        let TableFilterInstance = new UserTable(this.currentPage, this.perPage, this.totalPages, this.pageSnapshots, firebase);
        usersQuery = await TableFilterInstance.createUsersQuery(page, itemsPerPage);
      }
      const querySnapshot = usersQuery ? await usersQuery.get() : null;
      //return if no querySnapshot
      if (!querySnapshot) {
        this.loading = false;
        return;
      }
      //store data
      await this.store(querySnapshot);
      this.loading = false;
    },
  },
  /**
   * Setup Table/Filters
  */
  computed: {
    fields() {
      return [{
        name: '__slot:avatar',
        width: '30px',
        height: '45px',
        dataClass: 'text-center',
      }, {
        name: 'displayName',
        title: this.$t('tables.headings.name'),
        width: '30%',
      }, {
        name: 'provider',
        title: 'Provider',
        width: '20%',
      }, {
        name: 'email',
        title: this.$t('tables.headings.email'),
        width: '30%',
      },
      {
        name: '__slot:lastLogin',
        title: 'Last Login',
      }];
    },
  },
};
</script>
