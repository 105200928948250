const UserUtils = require('./utils/UserUtils');
const Swal = require('sweetalert2');
class UserTable extends UserUtils {
    
  CURRENT_PAGE = 1;
  TOTAL = 0;
  PAGE_SIZE = 10;
  PAGE_SNAPSHOTS = [];
  FIREBASE = null;

  /**
   * @param {*} currentPage 
   * @param {*} pageSize 
   * @param {*} total 
   * @param {*} data 
  */
  constructor(currentPage, pageSize, total, pageSnapshots, firebase) {
    super(); // Call the super constructor if UserUtils has its own constructor
    this.CURRENT_PAGE = currentPage;
    this.PAGE_SIZE = pageSize;
    this.TOTAL = total;
    this.PAGE_SNAPSHOTS = pageSnapshots;
    //Setup firebase
    this.FIREBASE   = firebase;

    if (!this.validate()) {
      throw new Error('Invalid Properties');
    }
  }

  validate() {
    return (
      !this.isNullOrUndefined(this.CURRENT_PAGE) &&
      !this.isNullOrUndefined(this.PAGE_SIZE) &&
      !this.isNullOrUndefined(this.TOTAL) &&
      !this.isNullOrUndefined(this.PAGE_SNAPSHOTS)
    );
  }


  async createUsersQuery(page, itemsPerPage) {
    const pageSize = parseInt(itemsPerPage);
    const targetPage = page;
    
    let usersTable = this.FIREBASE.firestore().collection('users');
    let usersQuery = usersTable.orderBy('email').limit(pageSize);
    
    if (targetPage > 1) {
      if (this.PAGE_SNAPSHOTS[targetPage - 1]) {
        usersQuery = usersQuery.startAfter(this.PAGE_SNAPSHOTS[targetPage - 1].end);
      } else {
        const skips = (targetPage - 1) * pageSize;
        const lastVisible = await usersTable.orderBy('email').limit(skips).get().then((snapshot) => {
          return snapshot.docs[snapshot.docs.length - 1];
        });
        usersQuery = usersQuery.startAfter(lastVisible)
      }
    }
    return usersQuery;
  }


  /**
   * @description Validate the current filter & call the corresponding function
   * @param {*} selectedFilter 
   */
  async handleFilterSelection(selectedFilter, filterChoices, isPaginationChange = false) {
    if (this.isNullOrUndefined(selectedFilter) ) {
      throw new Error('Invalid Filter');
    }

    let response = undefined;
    let options = {
      lastUserID: null,
      freeSub: 'isAdmin', //default value for this one
      state: null,
      logged: '30',
      completed: 1
    };


     switch (this.convertToString(selectedFilter)) {
      case 'state':
        options.state = !isPaginationChange ? await this.handleStateChoice() : filterChoices?.state;
        //if the user cancel the filter, return to the main menu
        if (options.state === undefined) {
          throw new Error('Invalid Filter');
        }
        response = await this.handleFilterSelectionByState(options.state);
        break;
      case 'logged':
        options.logged = !isPaginationChange ? await this.handleLoggedTypeChoice() : filterChoices?.logged;
        //if the user cancel the filter, return to the main menu
        if (options.logged === undefined) {
          throw new Error('Invalid Filter');
        }
        response = await this.handleFilterSelectionByLogged(options.logged, filterChoices?.lastUserID);
        options.lastUserID = response?.lastUserID || null;
        break;
      case 'completed':
        options.completed =  !isPaginationChange ? await this.handleCompletedTypeChoice() : filterChoices?.completed;
        if (options.completed === undefined) {
          throw new Error('Invalid Filter');
        }
        response = await this.handleFilterSelectionByCompleted(options.completed, filterChoices?.completed);
        options.completed = response?.completed || null;
        break;
      case 'freeSub':
        options.freeSub = !isPaginationChange ? await this.handleFreeSubTypeChoice() : filterChoices?.freeSub;
        //if the user cancel the filter, return to the main menu
        if (options.freeSub === undefined) {
          throw new Error('Invalid Filter');
        }
        response = await this.handleFilterSelectionByFreeSub(options.freeSub);
        break;
      default:
        throw new Error(
          'This feature is in maintenance mode because of the amount of data it needs to process. It will be back soon!'
        );
    }

    console.log('response', response);
    return {
      data: response,
      options: options
    }
  }

  // =================
  // COMPLETED AREA
  // =================

  async handleCompletedTypeChoice(){
    //We don't need to ask the user for a choice, but let have this function for consistency
    return 1;
  }

  async handleCompletedPagination () {
    let totalPageCount = 1;
    let query = this.FIREBASE.firestore().collection('completedExams')
    let users = await query.get();
    let totalUsers = users.docs.length;
    totalPageCount = Math.ceil(totalUsers / this.PAGE_SIZE);
    return totalPageCount;
  }

  async handleFilterSelectionByCompleted(CompletedOption) {
    const pageSize = parseInt(this.PAGE_SIZE);
    const targetPage = this.CURRENT_PAGE;
  
    let usersTable = this.FIREBASE.firestore().collection('completedExams');
    let usersQuery = usersTable.orderBy('TestCompleted').limit(pageSize);
  
    if (targetPage > 1) {
      const skips = (targetPage - 1) * pageSize;
      const lastVisible = await usersTable.orderBy('TestCompleted').limit(skips).get().then((snapshot) => {
        return snapshot.docs[snapshot.docs.length - 1];
      });
      usersQuery = usersQuery.startAfter(lastVisible);
    }
  
    let completedExamsQuery = usersQuery;
  
    let usersWithCompletedExams = await completedExamsQuery.get();
   
    //now, from unique replace the data by the data from each user by UserId field
    const collectionRef = this.FIREBASE.firestore().collection('users');

    let uniqueIds = usersWithCompletedExams.docs.map(doc => doc.data().UserId);
    uniqueIds = [...new Set(uniqueIds)];
    console.log(uniqueIds)

    //create a promisse to get the UserId from the completedExams collection and create a new array of the .get() promisses wuth the user
    const promises = uniqueIds.map(async (userId) => {
      const doc = await collectionRef.doc(userId).get();
      return doc;
    });
    
    let SubPagination = await this.handleCompletedPagination();
    const results = await Promise.all(promises);
    return {
      items: results,
      totalPageCount: SubPagination
    };
  }
  
  
  // =================
  // STATES AREA
  // =================
  async handleStateChoice() {
    
    let states = await this.FIREBASE.firestore().collection('states').get();
    let statesArray = states.docs.map((state) => {
      const data = state.data();
      return {
        id: data.abbreviation,
        name: data.name,
      };
    });
    
    
    const inputOptions = {};
    statesArray.forEach((state) => {
      inputOptions[state.id] = state.name;
    });
    
    const { value: type } = await Swal.fire({
      title: 'Select a state to filter',
      input: 'select',
      inputOptions: inputOptions,
      inputPlaceholder: 'Select a type',
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== '') {
            resolve();
          } else {
            resolve('You need to select a type');
          }
        });
      },
    });
    
    return type;
    
  }
  async handleStatePagination(type){
    let totalPageCount = 1;
    let query = this.FIREBASE.firestore().collection('users').where('stateCode', '==', type) 
    let users = await query.get();
    let totalUsers = users.docs.length;
    totalPageCount = Math.ceil(totalUsers / this.PAGE_SIZE);

    return totalPageCount;
  }

  
  async handleFilterSelectionByState(stateCode) {
    const pageSize = parseInt(this.PAGE_SIZE);
    const targetPage = this.CURRENT_PAGE;
    
    console.log('stateCode', stateCode);
    let usersTable = this.FIREBASE.firestore().collection('users');
    let usersQuery = usersTable.orderBy('email').limit(pageSize);
    
    if (targetPage > 1) {
      const skips = (targetPage - 1) * pageSize;
      const lastVisible = await usersTable.orderBy('email').where('stateCode', '==', stateCode).limit(skips).get().then((snapshot) => {
        return snapshot.docs[snapshot.docs.length - 1];
      });
      usersQuery = usersQuery.startAfter(lastVisible)
    }
    let isAdmin = await usersQuery.where('stateCode', '==', stateCode).get();
    //get all in a promisse all, and unique the array
    const [isAdminSnapshot] = await Promise.all([
      isAdmin,
    ]);
    let unique = [...new Set([...isAdminSnapshot.docs])];
    //remove the duplicates
    unique = unique.filter((item, index, self) => self.findIndex(t => t.id === item.id) === index)
    let SubPagination = await this.handleStatePagination(stateCode);

    return {
      items: unique,
      totalPageCount: SubPagination
    };
  }


  // =================
  // LOGGED OPTION AREA
  // =================
  async handleLoggedTypeChoice() {
    const { value: type } = await Swal.fire({
      title: 'From when do you want to filter?',
      input: 'select',
      inputOptions: {
        30: 'Last 30 days',
        60: 'Last 60 days',
        90: 'Last 90 days',
      },
      inputPlaceholder: 'Select a type',
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== '') {
            resolve();
          } else {
            resolve('You need to select a type');
          }
        });
      }
    });
    return type;
  }
  async handleLoggedPagination(lastLoginTimestamp) {
    //get users logged in the last type days, and return the pagination as the other method
    let query = await this.FIREBASE.firestore().collection('users').where('lastLogin', '>=', lastLoginTimestamp).get();
    return Math.ceil(query.size / this.PAGE_SIZE);
  }
  async handleFilterSelectionByLogged(type) {
    const pageSize = parseInt(this.PAGE_SIZE);
    const targetPage = this.CURRENT_PAGE;
    
    //create a firestore timestamp from today less 30 days
    const today = new Date();
    const days = parseInt(type);
    const lastLogin = new Date(today.setDate(today.getDate() - days));
    const lastLoginTimestamp = this.FIREBASE.firestore.Timestamp.fromDate(lastLogin);
  
  
    let usersTable = this.FIREBASE.firestore().collection('users');
    let usersQuery = usersTable.limit(pageSize);
    let isAdmin = null;
    if (targetPage > 1) {
      const skips = (targetPage - 1) * pageSize;
      const lastVisible = await usersTable
        .where('lastLogin', '>=', lastLoginTimestamp)
        .orderBy('lastLogin', 'desc')
        .limit(skips)
        .get()
        .then((snapshot) => {
          return snapshot.docs[snapshot.docs.length - 1];
        });
       usersQuery = usersTable.orderBy('lastLogin', 'desc').startAfter(lastVisible);
       isAdmin = await usersQuery
       .where('lastLogin', '>=', lastLoginTimestamp)
       .limit(pageSize)
       .get();
    }else{
      isAdmin = await usersQuery
      .where('lastLogin', '>=', lastLoginTimestamp)
      .orderBy('lastLogin', 'desc')
      .limit(pageSize)
      .get();
    }

  
    //get all in a promise all, and unique the array
    const [isAdminSnapshot] = await Promise.all([isAdmin]);
    let unique = [...new Set([...isAdminSnapshot.docs])];
  
    //remove the duplicates
    unique = unique.filter(
      (item, index, self) => self.findIndex((t) => t.id === item.id) === index
    );
  
    let SubPagination = await this.handleLoggedPagination(lastLoginTimestamp);
    
    return {
      items: unique,
      totalPageCount: SubPagination,
    };
  }
  

  // =================
  // FREE SUB AREA
  // =================
  async handleFreeSubTypeChoice() {
    const { value: type } = await Swal.fire({
      title: 'What type of free subscription do you want to filter?',
      input: 'select',
      inputOptions: {
        isAdmin: 'Admin',
        developer: 'Developer',
        freeSubscription: 'Free Subscription',
      },
      inputPlaceholder: 'Select a type',
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value !== '') {
            resolve();
          } else {
            resolve('You need to select a type');
          }
        });
      }
    });
    return type;
  }
  async handleFreeSubPagination(type){
    let totalPageCount = 1;
    switch (type) {
      case 'isAdmin':
        let isAdmin = await this.FIREBASE.firestore().collection('users').where('isAdmin', '==', true).get();
        totalPageCount = Math.ceil(isAdmin.size / this.PAGE_SIZE);
        break;
      case 'developer':
        let developer = await this.FIREBASE.firestore().collection('users').where('developer', '==', true).get();
        totalPageCount = Math.ceil(developer.size / this.PAGE_SIZE);
        break;
      case 'freeSubscription':
        let freeSubscription = await this.FIREBASE.firestore().collection('users').where('freeSubscription', '==', true).get();
        totalPageCount = Math.ceil(freeSubscription.size / this.PAGE_SIZE);
        break;
      default:
        break;
    }
    return totalPageCount;
  }

  
  async handleFilterSelectionByFreeSub(type) {
    const pageSize = parseInt(this.PAGE_SIZE);
    const targetPage = this.CURRENT_PAGE;
    
    let usersTable = this.FIREBASE.firestore().collection('users');
    let usersQuery = usersTable.orderBy('email').limit(pageSize);
    
    if (targetPage > 1) {
      const skips = (targetPage - 1) * pageSize;
      const lastVisible = await usersTable.orderBy('email').where(type, '==', true).limit(skips).get().then((snapshot) => {
        return snapshot.docs[snapshot.docs.length - 1];
      });
      usersQuery = usersQuery.startAfter(lastVisible)
    }
    let isAdmin = await usersQuery.where(type, '==', true).get();
    //get all in a promisse all, and unique the array
    const [isAdminSnapshot] = await Promise.all([
      isAdmin,
    ]);
    let unique = [...new Set([...isAdminSnapshot.docs])];
    //remove the duplicates
    unique = unique.filter((item, index, self) => self.findIndex(t => t.id === item.id) === index)
    let SubPagination = await this.handleFreeSubPagination(type);

    return {
      items: unique,
      totalPageCount: SubPagination
    };
  }

}

module.exports = UserTable;
